import React, { CSSProperties, ChangeEvent, useContext, useEffect, useRef, useState } from "react";
import { SessionContext } from "../context/session/SessionContext";
import classNames from "classnames";
import { isDevelopmentVersion } from "../test";

const DEBUG = isDevelopmentVersion();
const BLIND = false;

type PatternMatcherProps =
{
    imageIds: string[];
    threshold: number;
    expectedDuration?: number;

    depthConfidence?:number;
    widthConfidence?:number;

    onImageMatch: (match:boolean) => void;
    onImageReset?: () => void;
}

export function PatternMatcher({imageIds, threshold, depthConfidence, widthConfidence, expectedDuration=10, ...props} : PatternMatcherProps)
{
    const session = useContext(SessionContext);

    const refInputImage = useRef<HTMLInputElement>(null);

    const [stateImageURL, setImageURL] = useState("");

    const [stateMatch, setMatch] = useState<boolean|undefined>(undefined);
    const [stateMatchCount, setMatchCount] = useState<number|undefined>(undefined);
    const [stateAnalyzing, setAnalyzing] = useState(false);
    const [stateCommit, setCommit] = useState(false);
    const [stateError, setError] = useState(false);

    function takePhoto()
    {
        if (BLIND)
        {
            setCommit(true);
            setMatchCount(threshold);
            setMatch(true);
        }
        else
            refInputImage.current?.click();
    }

    async function onImageChange(event:ChangeEvent<HTMLInputElement>)
    {
        function sleep(ms:number)
        {
            return new Promise(resolve => setTimeout(resolve, ms));
        }

        if (event.target.files)
        {
            let file = event.target.files[0];

            if (stateImageURL)
            {
                URL.revokeObjectURL(stateImageURL);
            }

            setImageURL(URL.createObjectURL(file));

            props.onImageReset?.();

            setCommit(false);
            setAnalyzing(true);
            setMatchCount(undefined);
            setMatch(undefined);
            setError(false);

            try
            {
                const matchCount = BLIND ? threshold : await (session?.countImageMatches(file, imageIds, depthConfidence, widthConfidence) ?? -1);

                console.log('match result', imageIds.join('|'), matchCount);

                const match = matchCount >= threshold;

                if (!DEBUG || BLIND) setCommit(true);
                setMatchCount(matchCount);
                setMatch(match);
            }
            catch (error)
            {
                console.log(error);

                setError(true);
            }
            finally
            {
                setAnalyzing(false);
            }
        }
    }

    function releaseImage()
    {
        URL.revokeObjectURL(stateImageURL);
    }

    useEffect(() =>
    {
        if (stateMatch !== undefined && stateCommit)
        {
            props.onImageMatch(stateMatch);
        }
    }, [stateMatch, stateCommit]);


    useEffect(() => releaseImage, [stateImageURL]);

    const classes = classNames("pattern-matcher",
    {
        "has-image": stateImageURL != "",
        
        "error": stateError,
        "analyzing": stateAnalyzing,
        "match": stateMatch === true,
        "mismatch": stateMatch === false,
    });

    return <>
        <form action={undefined} className={classes}>
            <input id="image-capture" ref={refInputImage} type="file" accept="image/*" capture="environment"
                onChange={onImageChange} />

            <button type="button" className="main capture" onClick={takePhoto}>
                <img src={stateImageURL} />
            </button>

            { stateAnalyzing &&
                <div className="progress" style={{"--progress-duration":expectedDuration} as CSSProperties}>
                    <div className="progress-bar left"></div>
                    <div className="progress-bar right"></div>
                </div>
            }

            { DEBUG && !stateCommit && stateMatchCount !== undefined &&
                <div className="debug-info">
                    <span className="match-count">{stateMatchCount}</span>

                    <div className="buttons">
                        <button className="main commit" onClick={() => setCommit(true)}>Annehmen</button>
                    </div>
                </div>
            }
        </form>
    </>
}