import { usePuzzleMetaSelector, usePuzzleSelector } from ".";
import { MapEffect } from "../misc/MapEffect";

export default function useMapEffects()
{
    var effects : MapEffect[] = [];

    usePuzzleMetaSelector(puzzle => 
    {
        if (puzzle.selectMapEffect)
        {
            let effect = usePuzzleSelector(puzzle.selectMapEffect);

            if (effect)
            {
                effects.push(effect);
            }
        }
    });

    return effects;
}