import React, { PropsWithChildren, useEffect, useState } from "react";
import { PositionContext } from "./PositionContext";
import { Position } from "../../misc/Position";
import { usePuzzleSelector } from "../../hooks";
import { selectGameStarted } from "../../game/puzzles/Puzzle00_Start";

type DevicePositionProviderProps =
{
    
}

export function DevicePositionProvider({children} : PropsWithChildren<DevicePositionProviderProps>)
{
    const [geo, setGeolocation] = useState<GeolocationPosition|undefined>();

    const watchGPS = usePuzzleSelector(selectGameStarted);

    useEffect(() => // Deferred GPS watch
    {
        if (watchGPS)
        {
            navigator.geolocation.watchPosition(geo => setGeolocation(geo), null, {enableHighAccuracy:true});
        }
    }, [watchGPS])

    let data;
    if (geo && geo.coords.latitude && geo.coords.longitude)
    {
        data = {position:new Position(geo.coords.latitude, geo.coords.longitude)};
    }

    return <>
        <PositionContext.Provider value={data}>
            {children}
        </PositionContext.Provider>
    </>
}