import React, { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { useGeolocation } from "@uidotdev/usehooks";
import { PositionContext } from "./PositionContext";
import { Position } from "../../misc/Position";
import { useEventListener } from "usehooks-ts";
import { mapPixelToMeter } from "../../world/dimensions";

const TEST_POSITION = 
    new Position(
        parseFloat(localStorage.getItem('player.latitude') ?? "51.523846753271066"), 
        parseFloat(localStorage.getItem('player.longitude') ?? "7.481971350913982"));

type TestPositionProviderProps =
{
    
}

export function TestPositionProvider({children} : PropsWithChildren<TestPositionProviderProps>)
{
    let [position, setPosition] = useState<Position>(TEST_POSITION);
    const [pointerDown, setPointerDown] = useState<PointerEvent>();
    const [pointerMove, setPointerMove] = useState<PointerEvent>();

    if (pointerDown && pointerMove)
    {
        let de = mapPixelToMeter(pointerDown.pageX - pointerMove.pageX);
        let dn = -mapPixelToMeter(pointerDown.pageY - pointerMove.pageY);

        position = position!.moveByDistance(dn, de);
    }

    useEventListener('pointerdown', (event:PointerEvent) =>
    {
        if (event.target instanceof Element && isElementInMapLayer(event.target))
        {
            setPointerDown(event);
        }
    });
    useEventListener('pointermove', (event:PointerEvent) =>
    {
        if (pointerDown)
        {
            setPointerMove(event);
        }
    });
    useEventListener('pointerup', (event:PointerEvent) =>
    {
        setPosition(position);
        setPointerDown(undefined);
        setPointerMove(undefined);
    });

    useEffect(() => // Update Local Storage
    {
        localStorage.setItem('player.latitude', position.latitude.toString());
        localStorage.setItem('player.longitude', position.longitude.toString());

    }, [position]);

    let data = useMemo(() => ({position}), [position]);

    return <>
        <PositionContext.Provider value={data}>
            {children}
        </PositionContext.Provider>
    </>
}

function isElementInMapLayer(target:Element)
{
    while (target)
    {
        if (target.classList.contains('world'))
            // if (target.classList.contains('map'))
                return true;

        target = (target.parentElement as Element);
    }

    return false;
}