import { createContext } from "react";
import { SupportedLanguage, DEFAULT_LANG} from "../../i18n";
import { Entity } from "../../game/entites/Entity";

export interface EntityInspectorInfo
{
    titleArea?: HTMLElement;
    actionArea?: HTMLElement;
}

export const EntityInspectorContext = createContext<EntityInspectorInfo>({});

EntityInspectorContext.displayName = "EntityInspectorContext";
