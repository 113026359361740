import React, { PropsWithChildren, useEffect, useState } from "react";
import { SessionContext } from "./SessionContext";
import { ClientSession } from "./ClientSession";
import { io } from "socket.io-client";
import { useGameSelector } from "../../hooks";
import { selectGameId } from "../../game/status";

type RemoteSessionProviderProps =
{
    
}

export function RemoteSessionProvider({children} : PropsWithChildren<RemoteSessionProviderProps>)
{
    const [session, setSession] = useState<ClientSession|undefined>(undefined);

    const gameId = useGameSelector(selectGameId);

    useEffect(() =>
    {
        (async function connect()
        {
            var session = new ClientSession(io({path: window.location.pathname + "socket.io"}));

            await session.waitUntilReady(gameId);
    
            setSession(session);
        })();
    }, []);

    return <>
        <SessionContext.Provider value={session}>
            {children}
        </SessionContext.Provider>
    </>
}