import React, { createContext } from "react";
import { Position } from "../../misc/Position";

export interface PositionData
{
    position: Position;
}

export const PositionContext = createContext<PositionData|undefined>(undefined);

PositionContext.displayName = "PositionContext";
