import React from "react";
import i18next from 'i18next';
import { Trans, initReactI18next, useTranslation } from "react-i18next";

import de from '../lang/de.json';
import tr from '../lang/tr.json';

export type SupportedLanguage = "de"|"en"|"tr"|"ar";

export const DEFAULT_LANG:SupportedLanguage = "de";

i18next.use(initReactI18next);

const lng = localStorage.getItem('language') ?? DEFAULT_LANG;

await i18next.init({
    lng,
    // debug: true,

    interpolation:
    {
        escapeValue: false
    }
});

i18next.addResourceBundle('de', 'translation', de);
i18next.addResourceBundle('tr', 'translation', tr);

type TransParagraphsProps =
{
    i18nKey: string;
}

export function TransParagraphs({i18nKey} : TransParagraphsProps)
{
    const {t:trans} = useTranslation();

    var paragraphs = trans(i18nKey, { returnObjects: true });

    var pi = 0;

    return <>
        { paragraphs instanceof Array ?
            paragraphs.map((text) => <p key={pi}><Trans i18nKey={i18nKey + '.' + pi++} /></p>)
        : 
            typeof paragraphs == "string" ? <p>{paragraphs}</p>
        : undefined
        }
    </>
}

export class PolylingualString
{
    constructor(readonly i18nKey:string)
    {

    }
}