import React from "react";
import { CaseReducer, CreateSliceOptions, PayloadAction, Slice, SliceCaseReducers, createSlice } from "@reduxjs/toolkit";

import { ClockState } from "../status";
import { Entity } from "../entites/Entity";
import { Perspective } from "../../misc/Perspective";
import { Quest } from "../quests/Quest";
import { EntityView, EntityViewLoader } from "./view";
import { MapEffect } from "../../misc/MapEffect";

class PuzzleManager
{
    public readonly slices : PuzzleSlice[] = [];

    private views : Map<string, EntityView> = new Map;
    
    private promisedViews? : [string, EntityViewLoader][] = [];

    addPuzzleSlice(slice:PuzzleSlice)
    {
        this.slices.push(slice);
    }

    addPuzzleView(name:string, view:EntityView|EntityViewLoader)
    {
        if (view instanceof Promise)
            this.promisedViews?.push([name, view]);
        else
            this.views.set(name, view);
    }

    findViewByName(name:string)
    {
        return this.views.get(name);
    }

    async finishLoading()
    {
        if (this.promisedViews)
        {
            for (let [name, promise] of this.promisedViews)
            {
                this.views.set(name, (await promise).default);
            }

            delete this.promisedViews;
        }
    }
}

export const puzzleManager = new PuzzleManager();

interface PuzzleState
{
    solvable: false|number;
}

type PuzzleSliceCaseReducers<State> = SliceCaseReducers<State> &
{
    ["markAsSolved"]: CaseReducer<State, PayloadAction<undefined>>;
}

type PuzzleSelector<State, Return = any> = (state: State, stateClock: ClockState, ...args: any[]) => Return;

export type PuzzleSliceSelectors<State = PuzzleState> = {[K: string]: PuzzleSelector<State>} &
{
    ["selectEntities"]: PuzzleSelector<State, Entity[]>;
    ["selectPerspective"]?: PuzzleSelector<State, Perspective|undefined>;
    ["selectQuest"]?: PuzzleSelector<State, Quest|undefined>;
    ["selectMapEffect"]?: PuzzleSelector<State, MapEffect|undefined>;
}

interface CreatePuzzleOptions
{
    views:
    {
        [K:string]: Promise<{default:(props:any) => React.JSX.Element}>;
    }
}

interface PuzzleSlice
<
    State = any, 
    CaseReducers extends PuzzleSliceCaseReducers<State> = PuzzleSliceCaseReducers<State>,
    Name extends string = string, 
    ReducerPath extends string = Name, 
    Selectors extends PuzzleSliceSelectors<State> = PuzzleSliceSelectors<State>
>
extends Slice<State, CaseReducers, Name, ReducerPath, Selectors>
{
    test: string;
}

export function createPuzzleSlice
<
    State extends PuzzleState = PuzzleState, 
    CaseReducers extends PuzzleSliceCaseReducers<State> = PuzzleSliceCaseReducers<State>, 
    Name extends string = string, 
    ReducerPath extends string = Name, 
    Selectors extends PuzzleSliceSelectors<State> = PuzzleSliceSelectors<State>
>
(options:CreateSliceOptions<State, CaseReducers, Name, ReducerPath, Selectors> & CreatePuzzleOptions) : PuzzleSlice<State, CaseReducers, Name, ReducerPath, Selectors>
{
    var prevSlice = puzzleManager.slices[puzzleManager.slices.length-1];

    if (prevSlice)
    {
        options.extraReducers = (builder) =>
        {
            builder.addDefaultCase((state, action) =>
            {
                if (action.type == prevSlice.actions.markAsSolved().type)
                    state.solvable = Date.now();
            })
        }
    }

    var slice = createSlice(options);

    var puzzleSlice = Object.assign(slice,
    {
        test: "Hallo"

    }) as PuzzleSlice<State, CaseReducers, Name, ReducerPath, Selectors>;

    puzzleManager.addPuzzleSlice(puzzleSlice);

    for (let name in options.views)
    {
        let view =  options.views[name];

        puzzleManager.addPuzzleView(name, view);
    }
    
    return puzzleSlice;
}
