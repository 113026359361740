import { usePuzzleMetaSelector, usePuzzleSelector } from ".";
import { Quest } from "../game/quests/Quest";

export default function useQuest()
{
    var quest : Quest|undefined;

    usePuzzleMetaSelector(puzzle => 
    {
        if (puzzle.selectQuest)
        {
            let q = usePuzzleSelector(puzzle.selectQuest);

            if (q)
            {
                quest = q;
            }
        }
    });

    return quest;
}