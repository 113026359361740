import React, { PropsWithChildren, memo, useMemo, useState } from "react";

import { Provider } from "react-redux";
import { store } from "../game";

import { DevicePositionProvider } from "./position/DevicePositionProvider";
import { DeviceOrientationProvider } from "./orientation/DeviceOrientationProvider";
import { RemoteSessionProvider } from "./session/RemoteSessionProvider";
import { I18NextLanguageProvider } from "./language/I18NextLanguageProvider";
import { TestOrientationProvider } from "./orientation/TestOrientationProvider";
import { TestPositionProvider } from "./position/TestPositionProvider";
import { TestContext } from "./test/TestContext";

import { isDevelopmentVersion } from "../test";
import { selectGameStarted } from "../game/puzzles/Puzzle00_Start";
import { usePuzzleSelector } from "../hooks";

export type GameContextProps = 
{

}

export function GameContext({children} : PropsWithChildren<GameContextProps>)
{
    const [testGPS, setTestGPS] = useState(isDevelopmentVersion());
    const [testCompass, setTestCompass] = useState(isDevelopmentVersion());

    const test = useMemo(() =>
    ({
        get gps() { return testGPS },
        set gps(test:boolean) { setTestGPS(test) },

        get compass() { return testCompass },
        set compass(test:boolean) { setTestCompass(test) }

    }), [testGPS, testCompass]);

    const PositionProvider = test.gps ? TestPositionProvider : DevicePositionProvider;
    const OrientationProvider = test.compass ? TestOrientationProvider : DeviceOrientationProvider;

    return <>
        <PositionProvider>
        <OrientationProvider>
        <RemoteSessionProvider>
        <I18NextLanguageProvider>
            <TestContext.Provider value={test}>
                    <ContextShield>
                        {children}
                    </ContextShield>
            </TestContext.Provider>
        </I18NextLanguageProvider>
        </RemoteSessionProvider>
        </OrientationProvider>
        </PositionProvider>
    </>
}

const ContextShield = memo(function ContextShield({children} : PropsWithChildren<unknown>)
{
    return children;
});