import React, { CSSProperties, useContext, useEffect, useState } from "react";
import classNames from "classnames";

import { Entity } from "../../game/entites/Entity";
import { Location } from "../../game/entites/Location";
import { Collectible } from "../../game/entites/Collectible";

import { mapMeterToPixel, mapPositionToXY } from "../dimensions";
import { NPC } from "../../game/entites/NPC";
import useSound from "use-sound";
import { Doodad } from "../../game/entites/Doodad";

type EntityMarkerProps =
{
    entity: Entity

    invisible: boolean;
    inspectable?: boolean;
    inspecting: boolean;
    visiting: boolean;

    label?: string;

    onInspect: () => void;
}

export function EntityMarker({entity, ...props} : EntityMarkerProps)
{
    let [x,y] = mapPositionToXY(entity.position);
    let length = mapMeterToPixel(entity.radius * 2);

    const [talking, setTalking] = useState(false);

    if (entity instanceof NPC && entity.helloSound)
    {
        const [playHello, {stop:stopHello, sound:soundHello}] = useSound(entity.helloSound.url,
        {
            html5: false,
            onend: () => setTalking(false)
        });

        useEffect(() =>
        {
            if (soundHello && !entity.helloFinished)
            {
                playHello();

                setTalking(true);
            }
        }, [soundHello])
    }

    const classes = classNames("marker entity",
    {
        "invisible": props.invisible,
        "inspectable": props.inspectable,
        "inspecting": props.inspecting,
        "visiting": props.visiting,
        "closed": props.inspectable === undefined,
        "moving": entity.moving,
        talking
    });

    const styles = 
    {
        "--marker-x": x + 'px',
        "--marker-y": y + 'px',
        "--marker-length": length + 'px',
    } as CSSProperties;

    const type = entity instanceof Location ? "location" : entity instanceof Collectible ? "collectible" : entity instanceof NPC ? "npc" : entity instanceof Doodad ? "doodad" : "unknown";

    return <>
        <div data-entity-id={entity.id} data-entity-type={type} className={classes} style={styles}
            onClick={props.inspectable ? props.onInspect : undefined}>

            <div className="background"></div>

            <figure className="signage">
                {props.label &&
                    <figcaption>
                        {props.label}
                    </figcaption>
                }
            </figure>

        </div>
    </>
}
