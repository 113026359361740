import { createContext } from "react";

export type OrietationData =
{
    absolute: number;
}

export const OrientationContext = createContext(undefined as OrietationData|undefined);

OrientationContext.displayName = "OrientationContext";
