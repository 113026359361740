import { Position } from "../../misc/Position";
import { Entity } from "./Entity";

export class Doodad extends Entity
{
    public constructor(readonly id:string, readonly position:Position, readonly radius: number = 10)
    {
        super(id, position, radius);

        this.canInspect = false;
    }

}