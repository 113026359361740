import gsap from "gsap";
import classNames from "classnames";
import React, { ReactNode, useContext, useEffect, useRef, useState } from "react";

type EntityMenuProps =
{
    children: ReactNode;
}

export function EntityMenu(props : EntityMenuProps)
{
    const menuElement = useRef<HTMLElement>(null);

    let classes = classNames("entity-menu",
    {
    });

    return <>
        <nav className={classes}>
            {props.children}
        </nav>
    </>
}