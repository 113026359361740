import React, { Children, ReactNode, useContext } from "react"
import { useGameSelector, usePuzzleSelector } from "../hooks";
import { selectGameStarted } from "../game/puzzles/Puzzle00_Start";
import classNames from "classnames";
import { TestContext } from "../context/test/TestContext";
import { PositionContext } from "../context/position/PositionContext";
import { OrientationContext } from "../context/orientation/OrientationContext";
import { activateCompass, isDevelopmentVersion } from "../test";
import { GameClock } from "./GameClock";
import { GemCounter } from "./GemCounter";
import { selectGemCount } from "../game/status";

const CLOCK_SPEED = 1000;

type StatusBarProps = 
{

}

export function StatusBar({} : StatusBarProps)
{
    const test = useContext(TestContext);
    const gps = useContext(PositionContext);
    const compass = useContext(OrientationContext);

    const gems = useGameSelector(selectGemCount);

    let classes = classNames("status",
    {
        "visible": usePuzzleSelector(selectGameStarted)
    });

    function toggleGPS()
    {
        test.gps = !test.gps;
    }
    function toggleCompass()
    {
        if (test.compass)
            activateCompass();

        test.compass = !test.compass;
    }

    function snapshot()
    {
        var data = localStorage.getItem('persist:test');

        if (data)
        {
            localStorage.setItem('persist:snapshot', data);
        }
    }
    function restore()
    {
        var data = localStorage.getItem('persist:snapshot');

        if (data)
        {
            localStorage.setItem('persist:test', data);
        }

        location.reload();
    }
    function reset()
    {
        localStorage.clear();
        location.reload();
    }

    return <>
        <header className={classes}>
            <div className="left">
                <GemCounter count={gems} />

                { isDevelopmentVersion() && <>
                    <button className={classNames('gps', {'off': test.gps, 'error': !(test.gps || gps)})} onClick={toggleGPS} />
                    <button className={classNames('compass', {'off': test.compass, 'error': !(test.compass || compass)})} onClick={toggleCompass} />
                </> }
            </div>

            <GameClock interval={CLOCK_SPEED} />

            <div className="right">
                { isDevelopmentVersion() && <>
                    <button className="restore" onClick={restore} />
                    <button className="snapshot" onClick={snapshot} />
                    <button className="reset" onClick={reset} />
                </> }
            </div>
        </header>
    </>
}