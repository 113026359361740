import { PolylingualString } from "../../i18n";

export class Quest
{
    public id?: string;

    constructor(readonly prompt:PolylingualString)
    {

    }
}