
import { useDispatch, useSelector } from 'react-redux'
import type { TypedUseSelectorHook } from 'react-redux'

import type { GameState, GameDispatch } from '../game'
import type { ClockState } from '../game/status'

import { PuzzleSliceSelectors, puzzleManager } from '../game/puzzles/puzzle'

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useGameDispatch: () => GameDispatch = useDispatch
export const useGameSelector: TypedUseSelectorHook<GameState> = useSelector

export function usePuzzleSelector<Selected = unknown>(selector: (state:GameState, clockState:ClockState) => Selected) : Selected
{
    return useGameSelector((state) => selector(state, state.clock));

    // return useGameSelector createSelector([(state:GameState) => state], state => 

}

export function usePuzzleMetaSelector(selector: (puzzleSelectors:PuzzleSliceSelectors<GameState>) => void)
{
    for (let slice of puzzleManager.slices)
    {
        selector(slice.selectors);
    }
}
