import React, { CSSProperties } from "react";
import { Position } from "../../misc/Position";
import { mapMeterToPixel, mapPositionToXY } from "../dimensions";

type SearchPerimeterProps =
{
    center: Position;
    radius: number
}

export function SearchPerimeter({center, radius} : SearchPerimeterProps)
{
    let [x,y] = mapPositionToXY(center);
    let length = mapMeterToPixel(radius * 2);

    const styles = 
    {
        "--perimeter-x": x + 'px',
        "--perimeter-y": y + 'px',
        "--perimeter-length": length + 'px',
    } as CSSProperties;

    return <>
        <div className="search-perimeter" style={styles}>
            <div className="perimeter" />
        </div>
    </>
}