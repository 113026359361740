import React from "react";
import useQuest from "../hooks/useQuest";
import { MainMenuTitle } from "./MainMenuTitle";
import { usePuzzleSelector } from "../hooks/index";
import { selectGameStarted } from "../game/puzzles/Puzzle00_Start/index";
import { QuestDialog } from "./QuestDialog";
import classNames from "classnames";

type PopupManagerProps =
{
    hidden: boolean;
}

export function PopupManager({hidden} : PopupManagerProps)
{
    const quest = useQuest();

    let showTitle = !usePuzzleSelector(selectGameStarted);

    const classes = classNames("popups",
    {
        "hidden": hidden || (!showTitle && !quest)
    });

    return <>
        <aside className={classes}>
            { showTitle && <MainMenuTitle /> }

            { quest &&
                <QuestDialog quest={quest} />
            }
        </aside>
    </>
}