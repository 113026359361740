import { Reducer, ReducersMapObject, Tuple, combineReducers, configureStore } from "@reduxjs/toolkit";
import { PersistConfig, persistReducer, persistStore } from 'redux-persist';
import LocalStorage from 'redux-persist/lib/storage';
import { clearStateAfter, isDevelopmentVersion } from "../test";

// Uhr
import clock from './status';

// Puzzles
const puzzle00 = (await import('./puzzles/Puzzle00_Start')).default;
const puzzle01 = (await import('./puzzles/Puzzle01_PommesRotWeiss')).default;
const puzzle02 = (await import('./puzzles/Puzzle02_Stockheide')).default;
const puzzle03 = (await import('./puzzles/Puzzle03_HoeschMuseum')).default;
const puzzle04 = (await import('./puzzles/Puzzle04_WorkerDwellings')).default;
const puzzle05 = (await import('./puzzles/Puzzle05_Stolperstein')).default;
const puzzle06 = (await import('./puzzles/Puzzle06_MichallekPlatz')).default;
const puzzle07 = (await import('./puzzles/Puzzle07_Aschentonnen')).default;
const puzzle08 = (await import('./puzzles/Puzzle08_LöBo')).default;
const puzzle09 = (await import('./puzzles/Puzzle09_Spielplatz')).default;

import { puzzleManager } from './puzzles/puzzle';

clearStateAfter(3600 * 1000 * 6); // nach 6h alles zurücksetzen

await puzzleManager.finishLoading();

const rootReducer = combineReducers({
    [clock.name]: clock.reducer,
    [puzzle00.name]: puzzle00.reducer,
    [puzzle01.name]: puzzle01.reducer,
    [puzzle02.name]: puzzle02.reducer,
    [puzzle03.name]: puzzle03.reducer,
    [puzzle04.name]: puzzle04.reducer,
    [puzzle05.name]: puzzle05.reducer,
    [puzzle06.name]: puzzle06.reducer,
    [puzzle07.name]: puzzle07.reducer,
    [puzzle08.name]: puzzle08.reducer,
    [puzzle09.name]: puzzle09.reducer,
});

const persistConfig =
{
    key: "game",
    storage: LocalStorage,
}

export const store = configureStore({

    reducer: persistReducer(persistConfig, rootReducer),

    middleware: () => new Tuple()

    // devTools: false

});

export const persistor = persistStore(store);

// Infer the `GameState` and `GameDispatch` types from the store itself
export type GameState = ReturnType<typeof store.getState>
export type GameDispatch = typeof store.dispatch
