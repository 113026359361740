import { PolylingualString } from "../../i18n";
import { Quest } from "./Quest";

export class FindEntityByPhotoQuest extends Quest
{
    public match? : boolean;

    public messageMatch?: PolylingualString;
    public messageMismatch?: PolylingualString;

    constructor(prompt:PolylingualString, readonly imageId: string, readonly threshold:number)
    {
        super(prompt);
    }
}
