import { Position } from "../../misc/Position";

export class Entity
{
    name?: string;

    canInspect: boolean = true;
    inspectRadius?: number; // in Metern
    inspectView?: string; // ViewID

    hidden: boolean = false; // Auf der Karte anzeigen?
    moving: boolean = false; // bewegt sich das Objekt?

    public constructor(readonly id:string, public position:Position, readonly radius: number = 10)
    {
        this.inspectRadius = this.radius * 1.25;
    }

    public canInspectFrom(position?:Position) : boolean
    {
        if (this.canInspect && this.inspectRadius && position)
        {
            let d = this.position.calcDistanceTo(position);

            return d < this.inspectRadius;
        }

        return this.canInspect;
    }

    public canVisitFrom(position?:Position) : boolean
    {
        if (this.inspectRadius && position)
        {
            let d = this.position.calcDistanceTo(position);

            return d < this.inspectRadius;
        }

        return false;
    }

}