import { PolylingualString } from "../../i18n";
import { Entity } from "../entites/Entity";
import { FindEntityQuest } from "./FindEntityQuest";

export class FindEntityByCompassQuest extends FindEntityQuest
{
    constructor(prompt:PolylingualString, readonly entity:Entity)
    {
        super(prompt, entity);
    }

}
