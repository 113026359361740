import React, { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { useGeolocation } from "@uidotdev/usehooks";
import { Position } from "../../misc/Position";
import { LanguageContext, LanguageSettings } from "./LanguageContext";
import { SupportedLanguage } from "../../i18n";
import i18next from "i18next";

type I18NextLanguageProviderProps =
{
    
}

export function I18NextLanguageProvider({children} : PropsWithChildren<I18NextLanguageProviderProps>)
{
    const [lang, setLang] = useState<SupportedLanguage>(i18next.language as SupportedLanguage);

    useEffect(() => void i18next.changeLanguage(lang), [lang]);

    const settings:LanguageSettings = useMemo(() => 
    ({
        get lang()
        {
            return lang
        },

        set lang(lang:SupportedLanguage)
        {
            setLang(lang);
        }
    }), [lang]);

    return <>
        <LanguageContext.Provider value={settings}>
            {children}
        </LanguageContext.Provider>
    </>
}