import { PolylingualString } from "../../i18n";
import { Area } from "../../misc/Area";
import { Quest } from "./Quest";

export class SearchAreaQuest extends Quest
{
    constructor(prompt:PolylingualString, readonly area:Area)
    {
        super(prompt);
    }
}
