import { usePuzzleMetaSelector, usePuzzleSelector } from ".";
import { Entity } from "../game/entites/Entity";
import { Doodad } from "../game/entites/Doodad";
import { Position } from "../misc/Position";

const DOODADS =
[
    new Doodad("flower1", new Position(51.52397188291788, 7.481384109940598)),
    new Doodad("flower2", new Position(51.52296415767161, 7.481176895985689)),
    new Doodad("flower3", new Position(51.52300282568842, 7.482181819233424)),
    new Doodad("crossing", new Position(51.527418770265456, 7.483095521855284), 30),
    new Doodad("tree", new Position(51.52443632924878, 7.482418529459252), 20),
    new Doodad("sandbox", new Position(51.525021617299316, 7.4826633472720685)),
    new Doodad("shrub1", new Position(51.52950740060724, 7.483436585299372), 20),
    new Doodad("shrub2", new Position(51.529014638755555, 7.483230013539586), 20),
    new Doodad("car", new Position(51.52750384007133, 7.48344298272409), 10),
    new Doodad("bike", new Position(51.526615129451585, 7.482605701362023), 10),
    new Doodad("house", new Position(51.52555864105795, 7.482043558758291), 20),
    // new Doodad("houses", new Position(51.523956228746464, 7.48248127341175), 25),
    new Doodad("stadium", new Position(51.52457025994519, 7.481591543198281), 20),
]

export default function useGameEntites()
{
    var entities : Entity[] = [];

    usePuzzleMetaSelector(puzzle => 
    {
        entities.push(...usePuzzleSelector(puzzle.selectEntities));
    });

    entities.push(...DOODADS);

    return entities;
}