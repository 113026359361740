import React, { createContext } from "react";

export interface TestOptions
{
    gps: boolean;
    compass: boolean;
}

export const TestContext = createContext<TestOptions>({gps:false, compass:false});

TestContext.displayName = "TestContext";
