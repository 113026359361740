import { PolylingualString } from "../../i18n";
import { Position } from "../../misc/Position";
import { Sound } from "../../misc/Sound";
import { Entity } from "../entites/Entity";
import { FindEntityQuest } from "./FindEntityQuest";
import { Quest } from "./Quest";

export class FindEntityByPerimeterQuest extends FindEntityQuest
{
     sound?: Sound;
     minDistance?: number

    constructor(prompt:PolylingualString, readonly entity:Entity, readonly center:Position, readonly radius:number, readonly hintImageURL?: string)
    {
        super(prompt, entity);
    }
}
