import React from "react";
import { useGameDispatch, useGameSelector } from "../hooks";
import { selectTimeRemaining, tickOffTime } from "../game/status";
import { useInterval } from "usehooks-ts";
import classNames from "classnames";

type GameClockProps =
{
    interval: number;
}

export function GameClock({interval} : GameClockProps)
{
    const dispatch = useGameDispatch();
    const remainingTime = useGameSelector(selectTimeRemaining);

    useInterval(() => dispatch(tickOffTime()), remainingTime ?? 0 > 0 ? interval : null);

    let classes = classNames("clock",
    {
        "started": remainingTime != undefined
    });

    if (remainingTime)
    {
        return <>
            <div className={classes}>
                <time> {formatTime(remainingTime)} </time>
            </div>
        </>
    }
}

function formatTime(time?:number) : string|undefined
{
    const SECOND = 1000;
    const MINUTE = SECOND * 60;
    const HOUR   = MINUTE * 60;

    if (time)
    {
        // const hh = Math.floor(time / HOUR);
        // time -= hh * HOUR;
        const mm = Math.floor(time / MINUTE);
        time -= mm * MINUTE;
        const ss = Math.floor(time / SECOND);

        // return hh.toString().padStart(2, '0') + ':' + mm.toString().padStart(2, '0') + ':' + ss.toString().padStart(2, '0')
        return mm.toString().padStart(2, '0') + ':' + ss.toString().padStart(2, '0')
    }
}