const EARTH_RADIUS = 6371e3;
// Earth’s radius, sphere
// const EARTH_RADIUS = 6378137;

export class Position
{
    constructor(readonly latitude: number, readonly longitude: number)
    {

    }

    calcDistanceTo(that:Position)
    {
        if (this.latitude == that.latitude && this.longitude == that.longitude)
            return 0;

        const c1 = this;
        const c2 = that;

        const φ1 = c1.latitude * Math.PI/180; // φ, λ in radians
        const φ2 = c2.latitude * Math.PI/180;
        const Δφ = (c2.latitude-c1.latitude) * Math.PI/180;
        const Δλ = (c2.longitude-c1.longitude) * Math.PI/180;

        const a =   Math.sin(Δφ/2) * Math.sin(Δφ/2) +
                    Math.cos(φ1) * Math.cos(φ2) *
                    Math.sin(Δλ/2) * Math.sin(Δλ/2);
        const c =   2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));

        const d = EARTH_RADIUS * c; // in metres

        return d;
    }

    calcDegreeTo(that:Position)
    {
        const phiK = (that.latitude * Math.PI) / 180.0;
        const lambdaK = (that.longitude * Math.PI) / 180.0;
        const phi = (this.latitude * Math.PI) / 180.0;
        const lambda = (this.longitude * Math.PI) / 180.0;
        const psi =
            (180.0 / Math.PI) *
            Math.atan2(
            Math.sin(lambdaK - lambda),
            Math.cos(phi) * Math.tan(phiK) -
                Math.sin(phi) * Math.cos(lambdaK - lambda)
            );
            
        return Math.round(psi);
    }

    moveByDistance(dn:number, de:number) : Position
    {
        //Position, decimal degrees
        let lat = this.latitude;
        let lon = this.longitude;

        //Coordinate offsets in radians
        let dLat = dn/EARTH_RADIUS
        let dLon = de/(EARTH_RADIUS*Math.cos(Math.PI*lat/180))

        //OffsetPosition, decimal degrees
        let latOffset = lat + dLat * 180/Math.PI
        let lonOffset = lon + dLon * 180/Math.PI

        return new Position(latOffset, lonOffset);
    }

}