import React, { CSSProperties, Touch, TouchEvent, useEffect, useRef, useState } from "react";

import { CSSTransition, Transition } from 'react-transition-group';

import { puzzleManager } from "../game/puzzles/puzzle";

import { Entity } from "../game/entites/Entity";
import { EntityMenu } from "./EntityMenu";
import { EntityMenuButton } from "./EntityMenuButton";
import { EntityView } from "../game/puzzles/view";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import $ from "jquery";
import { EntityInspectorContext, EntityInspectorInfo } from "../context/entity/EntityInspectorContext";
import classNames from "classnames";

type EntityInspectorProps =
{
    entity?: Entity;

    onDisregard: () => void;
}


export function EntityInspector({entity, onDisregard} : EntityInspectorProps)
{
    const articleRef = useRef<HTMLElement>(null);
    const refEntityMenuTitle = useRef<HTMLHeadingElement>(null);

    const [stateEntity, setEntity] = useState<Entity|undefined>(undefined);

    const [stateScrollData, setScrollData] = useState<ScrollData|undefined>(undefined);

    const { contextSafe:withGSAP } = useGSAP();

    if (entity?.inspectView && !stateEntity)
    {
        setEntity(entity);
    }
    else if (entity == undefined && window.scrollY != 0)
    {
        window.scrollTo({top: 0, behavior: "smooth"});
    }

    function enter()
    {
        const tl = gsap.timeline();

        tl.add(() => 
        {
            articleRef.current?.classList?.remove('transition-exit');
        });

        tl.fromTo(articleRef.current,
        {
            duration: 0.5,
            ease: "power1.in",
            y: "100vh"
        },
        {
            y: 0,
            clearProps: "transform"
        });

        tl.add(() => articleRef.current?.classList?.add('transition-enter-done'));
    }
    function exit()
    {
        const tl = gsap.timeline();

        tl.add(() => 
        {
            articleRef.current?.classList?.add('transition-exit');
        });

        tl.fromTo(articleRef.current,
        {
            y: stateScrollData?.overscroll 
        },
        {
            duration: 0.5,
            ease: stateScrollData?.overscroll ? "power1.out" : "power1.in",
            y: "100vh",
            clearProps: '--overscroll'
        });

        tl.add(() => 
        {
            articleRef.current?.classList?.remove('transition-enter-done');

            setScrollData(undefined);
        });
    }

    // useEffect(() =>
    // {
    //     gsap.set(articleRef.current, {y:"100vh"});
    //     window.scrollY = 0;
    // }, []);

    const EntityView : EntityView|undefined =
        stateEntity 
            ? puzzleManager.findViewByName(stateEntity.inspectView!)
            : undefined;

    let context : EntityInspectorInfo|null = 
    {
        titleArea: refEntityMenuTitle.current ?? undefined
    };

    return <>
        <EntityInspectorContext.Provider value={context}>
            <main>
                <Transition in={entity != undefined} nodeRef={articleRef}  timeout={1000}
                // unmountOnExit
                    onEnter={withGSAP(enter)} onExit={withGSAP(exit)}
                    onExited={() => setEntity(undefined)}>
                    <article ref={articleRef} 
                        data-entity-id={stateEntity?.id} 
                        data-view-id={stateEntity?.inspectView}
                        className={classNames({"hidden": !stateEntity})}
                        >
                        
                        <EntityMenu>
                            <EntityMenuButton type="exit" onClick={onDisregard} />

                            <h1 ref={refEntityMenuTitle}></h1>
                        </EntityMenu>

                        <div className="background dimmable"></div>

                        <div className="content">
                            { EntityView && <EntityView entity={stateEntity!} onExit={onDisregard} /> }
                        </div>
                    </article>
                </Transition>
            </main>
        </EntityInspectorContext.Provider>
    </>
}

class ScrollData
{
    readonly minWindowScrollY = 0;
    readonly maxWindowScrollY = document.body.clientHeight - window.innerHeight;

    readonly startWindowScrollY: number

    readonly touchStart: Touch
    touchMove?: Touch

    constructor(touch:Touch)
    {
        this.startWindowScrollY = window.scrollY;

        this.touchStart = touch;
    }

    get hasScrolledToTop()
    {
        return window.scrollY == 0;
    }

    get hasScrolledToBottom()
    {
        return (window.scrollY + window.innerHeight) > document.body.clientHeight - 1;
    }

    get overscroll() : number
    {
        if (this.touchMove)
        {
            let touchDiffY = this.touchMove.clientY - this.touchStart.clientY;

            if (touchDiffY > 0)
            {
                return Math.max(0, touchDiffY - (this.minWindowScrollY + this.startWindowScrollY));
            }
            else
            {
                const MAX_OVERSCROLL = -1/2 * window.innerHeight;

                let overscroll = Math.abs(Math.min(0, touchDiffY + (this.maxWindowScrollY - this.startWindowScrollY)));

                if (overscroll)
                {
                    let progress = overscroll / window.innerHeight;

                    // progress = 1 + -Math.pow(1 - (progress), 1.5);
                    progress = 2 * Math.log10(progress + 1);

                    return progress * MAX_OVERSCROLL;
                }
            }
        }

        return 0;
    }

    get shouldExit()
    {
        return (this.overscroll > (window.innerHeight / 4))
    }
}
