import React, { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { OrientationContext } from "./OrientationContext";
import { useInterval } from "usehooks-ts";

const INTERVAL = 60;

declare global
{
    var testOrientation: OrientationTestOptions|undefined;
}

interface OrientationTestOptions
{
    absolute: number;
    rotationRate: number;
}

type TestOrientationProviderProps =
{
    
}

export function TestOrientationProvider({children} : PropsWithChildren<TestOrientationProviderProps>)
{
    let [absolute, setAbsolute] = useState<number>(0);
    let [rotationRate, setRotationRate] = useState<number>(0);

    useEffect(() => // Communication with Console
    {
        window.testOrientation =
        {
            get absolute() { return absolute },
            set absolute(value:number) {setAbsolute(value)},
            get rotationRate() { return rotationRate },
            set rotationRate(value:number) { setRotationRate(value) },
        }

        return () =>
        {
            window.testOrientation = undefined;
        }
    }, [absolute, rotationRate]);

    useInterval(() => // Rotation Simulation
    {
        setAbsolute(absolute => 
        {
            let next = absolute + 360/INTERVAL * rotationRate

            if (next > 360)
                next -= 360;

            return next;
        });
    }, rotationRate ? 1000/INTERVAL : null);

    let data = useMemo(() => ({absolute}), [absolute]);

    return <>
        <OrientationContext.Provider value={data}>
            {children}
        </OrientationContext.Provider>
    </>
}