import { Entity } from "../entites/Entity";
import { FindEntityQuest } from "./FindEntityQuest";
import { Sound } from "../../misc/Sound";
import { PolylingualString } from "../../i18n";

export class FindEntityBySoundQuest extends FindEntityQuest
{
    constructor(prompt:PolylingualString, readonly entity:Entity, readonly sound: Sound, readonly minDistance: number)
    {
        super(prompt, entity);
    }
}
