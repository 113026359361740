import { Position } from "../../misc/Position";
import { Sound } from "../../misc/Sound";
import { Entity } from "./Entity";

export class NPC extends Entity
{
    public helloSound?: Sound;
    public helloFinished: boolean = false;

    public constructor(id:string, position:Position, readonly radius: number = 10)
    {
        super(id, position, radius);
    }

}