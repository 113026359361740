import React, { CSSProperties, useContext, useEffect, useRef, useState } from "react";
import { StreetMap } from "./StreetMap";
import { Entity } from "../game/entites/Entity";
import { Location } from "../game/entites/Location";
import { EntityMarker } from "./markers/EntityMarker";
import { BORSIGPLATZ_POSITION, Borsigplatz } from "../game/entites/Borsigplatz";
import { Position } from "../misc/Position";
import { PlayerMarker } from "./markers/PlayerMarker";
import gsap from "gsap";
import classNames from "classnames";
import { Perspective } from "../misc/Perspective";
import usePlayerPosition from "../hooks/usePlayerPosition";

import { useWorldTransform } from "./dimensions";
import useQuest from "../hooks/useQuest";
import { FindEntityByPerimeterQuest } from "../game/quests/FindEntityByPerimeterQuest";
import { SearchPerimeter } from "./area/SearchPerimeter";

type WorldViewProps =
{
    perspective: Perspective;

    entites: Entity[]; // Objekte mit denen interagiert werden kann
    inspectingEntity?: Entity; // Objekt, dass grade untersucht wird
    onInspectEntity: (entity:Entity) => void
    onUninspectEntity?: (entity:Entity) => void

    onVisitEntity?: (entity:Entity) => void
    onUnvisitEntity?: (entity:Entity) => void

    entitySpeed: number;
}

export function WorldView({perspective, entites, ...props} : WorldViewProps)
{
    const worldViewElement = useRef<HTMLDivElement>(null);
    const worldElement = useRef<HTMLDivElement>(null);
    
    const [stateVisitingEntity, setVisitingEntity] = useState(undefined as Entity|undefined);

    let playerPosition = usePlayerPosition();

    let inspectingEntity = props.inspectingEntity;
    let visitingEntity = findVisitingEntity(entites, playerPosition);

    if (visitingEntity)
    {
        playerPosition = visitingEntity.position;
    }

    useEffect(() =>
    {
        if (visitingEntity != stateVisitingEntity)
        {
            if (visitingEntity)
                props.onVisitEntity?.(visitingEntity);
            else if (stateVisitingEntity)
                props.onUnvisitEntity?.(stateVisitingEntity);
            setVisitingEntity(visitingEntity);
        }
    }, [visitingEntity, stateVisitingEntity]);

    // useEffect(() =>
    // {
    //     if (inspectingEntity && !inspectingEntity?.canInspectFrom(playerPosition))
    //     {
    //         props.onUninspectEntity?.(inspectingEntity);
    //     }
    // }, [inspectingEntity, playerPosition]);

    let centerPosition = perspective.position ?? playerPosition ?? BORSIGPLATZ_POSITION;
    let focusPosition = props.inspectingEntity?.position;

    const worldViewStyles = useWorldTransform(perspective, centerPosition, focusPosition);

    useEffect(() => // Rotation
    {
        let timeline : gsap.core.Timeline;
        if (perspective.rotateDuration)
            timeline = gsap.timeline()
                .to(worldElement.current,
                {
                    ease: "linear",
                    duration: perspective.rotateDuration,
                    "--world-rotate": "360deg",
                    repeat: -1
                });

        return () =>
        {
            timeline?.kill();

            timeline = gsap.timeline()
                .to(worldElement.current,
                {
                    duration: 1,
                    "--world-rotate": "0deg",
                });
        }
    }, [perspective.rotateDuration]);

    let worldClasses = classNames("world",
    {
        "inspecting": props.inspectingEntity
    })

    let markerStyles =
    {
        '--entity-speed': props.entitySpeed
    } as CSSProperties

    const quest = useQuest();

    return <>
        <div className="world-view" style={worldViewStyles} ref={worldViewElement}>
            <div className="focus-view">
                <div className="background"></div>

                <div className={worldClasses} ref={worldElement}>
                    <div className="layer map">
                        <StreetMap />
                    </div>

                    <div className="layer area">
                        { quest instanceof FindEntityByPerimeterQuest && 
                            <SearchPerimeter center={quest.center} radius={quest.radius} />
                        }
                    </div>

                    <div className="layer markers" style={markerStyles}>
                        {[...generateMarkers(entites, inspectingEntity, visitingEntity, props.onInspectEntity, playerPosition)]}
                    </div>
                </div>
            </div>
        </div>
    </>
}

function * generateMarkers(entites:Entity[], inspectingEntity:Entity|undefined, visitingEntity:Entity|undefined, 
    onInspectEntity: (entity:Entity) => void, playerPosition?:Position)
{
    for (let entity of entites)
    {
        let entityProps =
        {
            entity,

            inspectable: entity.canInspect ? entity.canInspectFrom(playerPosition) : undefined,

            inspecting: entity == inspectingEntity,
            visiting: entity == visitingEntity,

            invisible: entity.hidden && !(entity == visitingEntity),

            onInspect: () => onInspectEntity(entity),

            label: entity instanceof Borsigplatz ? entity.label : undefined
        }

        yield <EntityMarker key={entity.id} {...entityProps} />;
    }

    if (playerPosition)
    {
        yield <PlayerMarker key="player" position={playerPosition} 
                    visiting={visitingEntity} inspecting={inspectingEntity} />;
    }
}

function findVisitingEntity(entites:Entity[], playerPosition?:Position) : Entity|undefined
{
    for (let entity of entites)
        if (entity.canInspect && entity.canVisitFrom(playerPosition))
            return entity;
}
