import React, { PropsWithChildren, useMemo, useState } from "react";
import { OrientationContext, OrietationData } from "./OrientationContext";
import { useEventListener } from "usehooks-ts";

declare global
{
    interface WindowEventMap
    {
        'deviceorientationabsolute': DeviceOrientationEvent;
    }
}

type DeviceOrientationProviderProps =
{
    
}

export function DeviceOrientationProvider({children} : PropsWithChildren<DeviceOrientationProviderProps>)
{
    const [absolute, setAbsolute] = useState<number>();

    // fetch OrientationData in Mobile Safari
    useEventListener('deviceorientation', (event:DeviceOrientationEvent) =>
    {
        if ((event as any).webkitCompassHeading)
        {
            setAbsolute((event as any).webkitCompassHeading);
        }
    });

    // fetch OrientationData on Android
    useEventListener('deviceorientationabsolute', (event:DeviceOrientationEvent) =>
    {
        if (event.alpha !== null)
        {
            setAbsolute(Math.abs(event.alpha - 360));
        }
    });

    let data = useMemo(() => absolute ? {absolute} : undefined, [absolute]);

    return <>
        <OrientationContext.Provider value={data}>
            {children}
        </OrientationContext.Provider>
    </>
}