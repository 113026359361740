import React, { CSSProperties, useContext, useState } from "react";
import { Entity } from "../../game/entites/Entity";
import { Position } from "../../misc/Position";
import { mapMeterToPixel, mapPositionToXY } from "../dimensions";
import classNames from "classnames";

type PlayerMarkerProps =
{
    position: Position;

    visiting?: Entity;
    inspecting?: Entity;
}

export function PlayerMarker({position, visiting, inspecting} : PlayerMarkerProps)
{
    let [x,y] = mapPositionToXY(position);

    let length = 25;

    if (visiting)
    {
        length = mapMeterToPixel(visiting.radius * 2) * 1.75;

        if (inspecting)
        {
            length = mapMeterToPixel(inspecting.radius * 2) * 3;
        }
    }

    const styles = 
    {
        "--marker-x": x + 'px',
        "--marker-y": y + 'px',
        "--marker-length": length + 'px',
    } as CSSProperties;

    const classes = classNames("marker player",
    {
        "inspecting": inspecting != undefined,
        "visiting": visiting != undefined
    })

    return <>
        <div className={classes} style={styles}>
            <div className="background"></div>
        </div>
    </>
}
