import classNames from "classnames";
import React from "react";
import { isDevelopmentVersion } from "../test";

type GemCounterProps =
{
    count: number;
}

export function GemCounter({count} : GemCounterProps)
{
    return <div className={classNames("gem-counter", {test:isDevelopmentVersion()})}>
        <span className="count">{count}</span>
    </div>
}