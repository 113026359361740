import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const MELTING_POINT = 890;

export const DRAMA_TIME = 1000 * 60 * 10; // 10 Minuten

export type ClockState =
{
    gameId?: string;

    timeStarted?: number,
    timeRemaining?: number,
    timeGameOver?: number,
    timeOverall?: number,
    timeOverallBoss?: number,

    timeToBoss?: number;

    gamover?: boolean;
    stopped?: boolean;
    finished?: boolean;

    gems: number;
}

const slice = createSlice({
    name: "clock",

    initialState:
    {
        gems: 0
    } as ClockState,

    reducers:
    {
        saveSessionId(state, action: PayloadAction<string>)
        {
            state.gameId = action.payload;
        },

        startTheClock(state, action: PayloadAction<number>)
        {
            state.timeStarted = Date.now();
            state.timeOverall = action.payload;
            state.timeRemaining = action.payload;
            state.timeGameOver = state.timeStarted + state.timeRemaining;
        },

        tickOffTime(state)
        {
            if (state.timeGameOver && !state.stopped)
            {
                const remaining = state.timeGameOver - Date.now();

                if (remaining <= 0)
                {
                    state.timeRemaining = 0;
                    state.stopped = true;
                    state.gamover = true;
                }
                else
                {
                    state.timeRemaining = remaining;
                }
            }
        },

        increaseGemCount(state)
        {
            state.gems++;
        },

        killTheTime(state)
        {
            state.timeToBoss = state.timeOverall! - state.timeRemaining!;

            if (state.timeRemaining! > DRAMA_TIME)
            {
                const timeshift = state.timeRemaining! - DRAMA_TIME

                state.timeStarted! -= timeshift;
                state.timeGameOver! -= timeshift;
                state.timeRemaining! -= timeshift;
                state.timeOverallBoss = DRAMA_TIME;
            }
            else
            {
                state.timeOverallBoss = state.timeRemaining!;
            }
        },

        stopTheClock(state)
        {
            state.stopped = true;
        },

        finishTheGame(state)
        {
            state.stopped = true;
            state.finished = true;
        }
    },

    selectors:
    {
        selectGameId(clockState)
        {
            return clockState.gameId;
        },

        selectTimeRemaining(clockState)
        {
            return clockState.timeRemaining;
        },

        selectTimeToBoss(clockState)
        {
            return clockState.timeToBoss!;
        },

        selectTimeToFinish(clockState)
        {
            return (clockState.timeOverallBoss! - clockState.timeRemaining!) + clockState.timeToBoss!;
        },

        selectTemperatur(state)
        {
            if (!(state.timeRemaining && state.timeGameOver && state.timeStarted))
                return 0;

            const timeTotal = state.timeGameOver - state.timeStarted;
            const time = timeTotal-state.timeRemaining;

            return Math.round((time / timeTotal) * MELTING_POINT);
        },

        selectGameOver(state)
        {
            return state.gamover === true;
        },

        selectGemCount(state)
        {
            return state.gems;
        }
    }
});

export default slice;

export const { selectGameId, selectTimeRemaining, selectTimeToBoss, selectTimeToFinish, selectTemperatur, selectGemCount, selectGameOver } = slice.selectors;
export const { saveSessionId, startTheClock, tickOffTime, killTheTime, stopTheClock, finishTheGame, increaseGemCount } = slice.actions;
