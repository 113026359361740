import React, { useEffect } from "react";
import { selectGameOver } from "../game/status";
import { useGameSelector } from "../hooks";
import classNames from "classnames";
import $ from "jquery";

type GameOverProps =
{
    
}

export function GameOver({} : GameOverProps)
{
    const over = useGameSelector(selectGameOver);

    function retry()
    {
        localStorage.clear();
        location.reload();
    }

    useEffect(() => // Scrollbar ausblenden
    {
        if (over)
        {
            $(document.body).addClass('no-scroll');

            return () => void $(document.body).removeClass('no-scroll');
        }
    }, [over])

    return <>
        <aside className={classNames("gameover", {over})}>
            <div className="gameover-container">
                <h2>Game Over</h2>

                <p>
                    Leider ist es dir nicht gelungen die Meisterschale rechtzeitig vor dem Einschmelzen zu bewahren.
                </p>

                <button onClick={retry}>Erneut versuchen?</button>
            </div>
        </aside>
    </>
}