import React, { useCallback, useContext, useReducer, useState } from "react";

import useGameEntites from "./hooks/useGameEntites";
import usePerspective from "./hooks/usePerspective";

import { GameClock } from "./status/GameClock";
import { WorldView } from "./world/WorldView";
import { Entity } from "./game/entites/Entity";
import { EntityInspector } from "./inspector/EntityInspector";
import { PopupManager } from "./popup/PopupManager";
import { StatusBar } from "./status/StatusBar";
import { Footer } from "./footer/Footer";
import { isDevelopmentVersion } from "./test";
import { GameOver } from "./popup/GameOver";

const CLOCK_SPEED = 1000;

type GameInterfaceProps =
{

}

export function GameInterface({} : GameInterfaceProps)
{
    const entites = useGameEntites();
    const perspective = usePerspective();

    const [stateInspectingEntity, setInspectingEntity] = useState(undefined as Entity|undefined);

    function onEntityInspect(entity:Entity)
    {
        if (entity.inspectView)
        {
            setInspectingEntity(entity);
        }
    }

    function onEntityUninspect(entity:Entity)
    {
        if (stateInspectingEntity == entity)
            setInspectingEntity(undefined);
    }

    let hidePopups = stateInspectingEntity != undefined;

    return <>
        <WorldView
            entites={entites} 
            inspectingEntity={stateInspectingEntity} 
            onInspectEntity={onEntityInspect} onUninspectEntity={onEntityUninspect}
            perspective={perspective}
            entitySpeed={CLOCK_SPEED} />

        <EntityInspector entity={stateInspectingEntity} 
            onDisregard={ () => setInspectingEntity(undefined)} />

        <PopupManager hidden={hidePopups} />

        <StatusBar />

        <GameOver />

       { isDevelopmentVersion() && <Footer hidden={hidePopups} /> } 

    </>
}
