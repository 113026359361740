
import { createPuzzleSlice } from "../puzzle";

import { Borsigplatz } from "../../entites/Borsigplatz";
import { isDevelopmentVersion } from "../../../test";
import { createSelector } from "@reduxjs/toolkit";
import { ClockState } from "../../status.js";
import { Entity } from "../../entites/Entity.js";

const ID = "puzzle_home";

const VIEW_START = ID + "_start";
const VIEW_WHAT = ID + "_what";

const BORSIG_LOCATION = new Borsigplatz(ID);

type PuzzleState =
{
    solvable: false|number,
    started: boolean,
}

const puzzleSlice = createPuzzleSlice({
    name: ID,

    initialState:
    {
        solvable: false,
        started: false,
    } as PuzzleState,

    reducers:
    {
        allowToStart(state)
        {
            state.solvable = Date.now();
        },

        markAsSolved(state) { state.started = true; }
    },

    selectors:
    {
        selectStartAllowed(statePuzzle)
        {
            return statePuzzle.solvable;
        },

        selectGameStarted(statePuzzle)
        {
            return statePuzzle.started;
        },

        selectEntities: createSelector([(state:PuzzleState) => state], statePuzzle =>
        {
            if (statePuzzle.started)
                return [];

            // if (statePuzzle.solvable)
            // {
                BORSIG_LOCATION.label = isDevelopmentVersion() ? "testen" : 'starten';
                BORSIG_LOCATION.inspectView = VIEW_START;
            // }
            // else
            // {
            //     BORSIG_LOCATION.label = "?";
            //     BORSIG_LOCATION.inspectView = VIEW_WHAT;
            // }

            return [BORSIG_LOCATION];
        }),

        selectPerspective: createSelector([(state:PuzzleState) => state], statePuzzle =>
        {
            if (!statePuzzle.started)
            {
                return { position: BORSIG_LOCATION.position, rotateDuration: 200 };
            }
        })
    },

    views:
    {
        [VIEW_START]: import('./start'),
        // [WINDOW_WHAT]: import('./what')
    }
});

export const { selectStartAllowed, selectGameStarted } = puzzleSlice.selectors;
export const { allowToStart, markAsSolved } = puzzleSlice.actions;

export default puzzleSlice;