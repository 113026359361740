import { createContext } from "react";
import { SupportedLanguage, DEFAULT_LANG} from "../../i18n";

export interface LanguageSettings
{
    get lang() : SupportedLanguage;
    set lang(value: SupportedLanguage);
}

export const LanguageContext = createContext<LanguageSettings>({lang:DEFAULT_LANG});

LanguageContext.displayName = "LanguageContext";
