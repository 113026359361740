import classNames from "classnames";
import React from "react";

type FooterProps =
{
    hidden: boolean;

}

export function Footer({hidden} : FooterProps)
{
    const classes = classNames(
    {
        "hidden": hidden
    });


    return <>
        <footer className={classes}>
            <figure className="beta" />
        </footer>
    </>
}