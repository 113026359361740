export function isDevelopmentVersion()
{
    return location.href.includes('test') || location.hash == "#test";
};

export function clearStateAfter(seconds:number)
{
    const now = Date.now();

    const last = parseInt(localStorage.getItem('lastTimePlayed') ?? now.toString());

    if (now - last > seconds)
        localStorage.clear();

    localStorage.setItem('lastTimePlayed', now.toString());
}

export async function activateCompass()
{
    try
    {
        const event = DeviceOrientationEvent as any;

        let response = await event.requestPermission();

        console.log('compass response', response);
    }
    catch
    {
        console.log('compass not supported');
    }
}
