import classNames from "classnames";
import React, { ReactNode, useContext, useState } from "react";

type EntityMenuButtonProps =
{
    type: string;

    onClick: () => void;
}

export function EntityMenuButton({type, ...props} : EntityMenuButtonProps)
{
    let classes = classNames("entity-menu-button", "type-" + type);

    return <>
        <button className={classes} onClick={props.onClick}>

        </button>
    </>
}