import { PolylingualString } from "../../i18n";
import { Entity } from "../entites/Entity";
import { Quest } from "./Quest";

export class FindEntityQuest extends Quest
{
    constructor(prompt:PolylingualString, readonly entity:Entity)
    {
        super(prompt);
    }
}
