import { CSSProperties, useState } from "react";
import { BORSIGPLATZ_POSITION } from "../game/entites/Borsigplatz";
import { Perspective } from "../misc/Perspective";
import { Position } from "../misc/Position";

const WORLD_LENGTH_PX = 6000; // Kantenlänge der Karte in Pixeln
const MAP_LENGTH = 1940; // Kantenlänge der Karte in Metern

export function useWorldTransform(perspective:Perspective, centerPosition:Position, focusPosition:Position|undefined)
{
    let [stateCenterPosition, setCenterPosition] = useState(BORSIGPLATZ_POSITION as Position);

    const [stateFocusPosition, setFocusPosition] = useState(undefined as Position|undefined);
    const [stateFocusOriginPosition, setFocusOriginPosition] = useState(undefined as Position|undefined);

    let immediate = false;
    if (centerPosition != stateCenterPosition)
    {
        setCenterPosition(stateCenterPosition = centerPosition);

        if (stateFocusPosition)
            immediate = true;
    }

    else if (focusPosition != stateFocusPosition)
    {
        setFocusPosition(focusPosition);

        if (stateFocusOriginPosition == undefined || focusPosition != undefined)
            setFocusOriginPosition(focusPosition);
        else if (focusPosition == undefined)
            setTimeout(() => setFocusOriginPosition(undefined), 1000);
    }

    const [x,y] = mapPositionToXY(stateCenterPosition);
    const [fx,fy] = mapPositionToXY(stateFocusPosition ?? stateCenterPosition);
    const [fox,foy] = mapPositionToXY(stateFocusOriginPosition ?? stateCenterPosition);

    const transform = 
    {
        "--world-length": WORLD_LENGTH_PX + 'px',

        "--world-x": x + "px",
        "--world-y": y + "px",
        "--world-rotate": 0 + "deg",

        "--world-focus-x": (x-fx) + "px",
        "--world-focus-y": (y-fy) + "px",
        "--world-focus-origin-x": (x-fox) + "px",
        "--world-focus-origin-y": (y-foy) + "px",
        "--world-focus-scale": focusPosition ? 18 : 1,

        "--world-focus-transition-duration": immediate ? "0s" : "1s",
        "--world-transition-duration": immediate ? "0s" : "0.25s"
    } as CSSProperties;

    return transform;
}

export function mapPositionToXY(position:Position) : [number, number]
{
    var posLatitude = new Position(position.latitude, BORSIGPLATZ_POSITION.longitude);
    var posLongitude = new Position(BORSIGPLATZ_POSITION.latitude, position.longitude);

    var distLatitude = BORSIGPLATZ_POSITION.calcDistanceTo(posLatitude);
    var distLongitude = BORSIGPLATZ_POSITION.calcDistanceTo(posLongitude);

    distLatitude *= Math.sign(BORSIGPLATZ_POSITION.latitude - position.latitude);
    distLongitude *= Math.sign(position.longitude - BORSIGPLATZ_POSITION.longitude);

    let x = mapMeterToPixel(distLongitude);
    let y = mapMeterToPixel(distLatitude);

    return [x,y];
}

export function mapMeterToPixel(distance:number) : number
{
    return distance / MAP_LENGTH * WORLD_LENGTH_PX;
}

export function mapPixelToMeter(distance:number) : number
{
    return distance / WORLD_LENGTH_PX * MAP_LENGTH;
}