import { Position } from "../../misc/Position";
import { Location } from "./Location";

export const BORSIGPLATZ_POSITION = new Position(51.52343710335579, 7.481690078429944);

export class Borsigplatz extends Location
{
    public label?: string;

    constructor(name:string)
    {
        super(name, BORSIGPLATZ_POSITION, 22);

        this.inspectRadius = undefined;
    }

    public canVisit()
    {
        return true;
    }
}
