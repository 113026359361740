import { Socket } from "socket.io-client";
import EventEmitter from "events";
import { Score } from "../../../../common/Score";
import { reject } from "lodash";
import { GameDevice } from "../../../../common/GameDevice";

export class ClientSession
{
    private gameId?: string;
    
    constructor(private readonly socket:Socket)
    {

    }

    public async waitUntilReady(gameId?:string)
    {
        return new Promise((ready, reject) =>
        {
            this.socket.once('connect', async () =>
            {
                if (gameId)
                {
                    if (! await this.resume(gameId))
                    {
                        reject("Session with ID '"+gameId+"' could not be continued.");

                        return;
                    }
                    
                    this.socket.on("connect", async () => await this.resume(gameId));
                }

                this.gameId = gameId;

                ready(true);
            });
        });
    }

    public async startGame() : Promise<string>
    {
        return new Promise<string>((started, rejected) =>
        {
            this.socket.emit('game:start', (gameId:string|null) => 
            {
                if (gameId)
                {
                    this.gameId = gameId;

                    this.socket.on("connect", async () => await this.resume(gameId));

                    started(gameId);

                    const device : GameDevice =
                    {
                        userAgent: navigator.userAgent,
                        userLanguage: navigator.language,

                        windowWidth: window.innerWidth,
                        windowHeight: window.innerHeight
                    }

                    this.socket.emit('game:device', device);
                }
                else
                    rejected("Game could not be started.");
            });
        });
    }

    private async resume(sessionId:string) : Promise<boolean>
    {
        return new Promise<boolean>((resumed:(ok:boolean) => void) =>
        {
            this.socket.emit('game:resume', sessionId, (ok:boolean) => 
            {
                resumed(ok);
            });
        });
    }

    public async startBossFight() : Promise<boolean>
    {
        return new Promise<boolean>((started:(ok:boolean) => void) =>
        {
            this.socket.emit('game:start:bossfight', (ok:boolean) => 
            {
                started(ok);
            });
        });
    }

    public async finishGame() : Promise<boolean>
    {
        return new Promise<boolean>((finished:(ok:boolean) => void) =>
        {
            this.socket.emit('game:finish', (ok:boolean) => 
            {
                finished(ok);
            });
        });
    }

    public countImageMatches(imageFile:File, imageNames:string[], depthConfidence?:number, widthConfidence?:number) : Promise<number>
    {
        return new Promise((fetched:(match:number) => void, reject:(reason:any) => void) =>
        {
            this.socket.emit('image:match', imageFile, imageNames, depthConfidence, widthConfidence, (match:number|false) => 
            {
                if (typeof match == "number")
                    fetched(match);
                else
                    reject(new Error('pattern matching error'));
            });
        });
    }

    public updateLevel(level:number)
    {
        return new Promise((resolve:(value:unknown) => void) =>
        {
            this.socket.emit('level', level, (ok:boolean) => 
            {
                resolve(ok);
            });
        });
    }

    public updateName(name:string)
    {
        return new Promise((resolve:(value:unknown) => void) =>
        {
            this.socket.emit('name', name, (ok:boolean) => 
            {
                resolve(ok);
            });
        });
    }

    public logEvent(name:string, data?:any)
    {
        this.socket.emit('game:event', name, data);
    }

    public scores()
    {
        return new Promise((resolve:(scores:[Score[], Score?]) => void, reject:(reason:any) => void) =>
        {
            this.socket.emit('highscore:list', (highscore:Score[]) => 
            {
                var ownScore;
                for (let score of highscore)
                {
                    if (typeof score.date == "string")
                        score.date = new Date(score.date as string);
                    if (score.id == this.gameId)
                        ownScore = score;
                }

                resolve([highscore,ownScore]);
            });
        });
    }

}