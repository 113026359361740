import { usePuzzleMetaSelector, usePuzzleSelector } from ".";
import { Perspective } from "../misc/Perspective";

export default function usePerspective()
{
    var perspective : Perspective = {};

    usePuzzleMetaSelector(puzzle => 
    {
        if (puzzle.selectPerspective)
        {
            let p = usePuzzleSelector(puzzle.selectPerspective);

            if (p)
            {
                perspective = p;
            }
        }
    });

    return perspective;
}