import classNames from "classnames";
import React, { memo, useContext, useState } from "react";

type MainMenuTitleProps =
{

}

export function MainMenuTitle({} : MainMenuTitleProps)
{
    return <>
        <header className="title">
            <img src="img/logo.png" />
            {/* <div className="cutout">
                <span className="the">Das</span>
                <span className="borsig">Borsig</span>
                <span className="enigma">Enigma</span>
            </div> */}
        </header>
    </>
}