import React, { CSSProperties, ReactNode, useContext, useEffect, useLayoutEffect, useState } from "react";
import { Quest } from "../game/quests/Quest";
import { FindEntityQuest } from "../game/quests/FindEntityQuest";
import usePlayerPosition from "../hooks/usePlayerPosition";
import classNames from "classnames";
import useSound from 'use-sound'
import { Position } from "../misc/Position";
import { FindEntityByDistanceQuest } from "../game/quests/FindEntityByDistanceQuest";
import { FindEntityBySoundQuest } from "../game/quests/FindEntityBySoundQuest";
import { FindEntityByCompassQuest } from "../game/quests/FindEntityByCompassQuest";
import { SearchAreaQuest } from "../game/quests/SearchAreaQuest";
import { useTranslation } from "react-i18next";
import { BORSIGPLATZ_POSITION } from "../game/entites/Borsigplatz";
import { FindEntityByPhotoQuest } from "../game/quests/FindEntityByPhotoQuest";
import { PatternMatcher } from "../media/PatternMatcher";
import { PolylingualString } from "../i18n";
import { OrientationContext } from "../context/orientation/OrientationContext";
import { FindEntityByPerimeterQuest } from "../game/quests/FindEntityByPerimeterQuest";

type QuestDialogProps = 
{
    quest: Quest;
}

export function QuestDialog({quest} : QuestDialogProps)
{
    const {t:trans} = useTranslation();

    return <>
        <dialog key={quest.id} className="quest" open data-id={quest.id}>

            <p className="prompt">{trans(quest.prompt.i18nKey)}</p>

            { quest instanceof FindEntityByDistanceQuest && <FindEntityByDistanceQuestPane quest={quest} /> }
            { quest instanceof FindEntityBySoundQuest && <FindEntityBySoundQuestPane quest={quest} /> }
            { quest instanceof FindEntityByCompassQuest && <FindEntityByCompassQuestPane quest={quest} /> }
            { quest instanceof FindEntityByPhotoQuest && <FindEntityByPhotoQuestPane quest={quest} /> }
            { quest instanceof FindEntityByPerimeterQuest && <FindEntityByPerimeterQuestPane quest={quest} /> }

            { quest instanceof SearchAreaQuest && <SearchAreaQuestPane quest={quest}/> }

        </dialog>
    </>
}

type QuestDialogPane =
{
    quest:FindEntityQuest;
    player:Position;
}

function FindEntityByDistanceQuestPane({quest} : {quest:FindEntityByDistanceQuest})
{
    const player = usePlayerPosition() ?? BORSIGPLATZ_POSITION

    let distance = Math.floor(quest.entity.position.calcDistanceTo(player));

    let targetClasses = classNames("target",
    {
        "found": quest.entity.canVisitFrom(player),
    });

    return <>
        <div className={targetClasses}>
            <figure className="icon" />
            <span className="distance"> { distance } m</span>
        </div>
    </>
}

function FindEntityBySoundQuestPane({quest} : {quest:FindEntityBySoundQuest})
{
    const player = usePlayerPosition() ?? BORSIGPLATZ_POSITION

    const distance = Math.floor(quest.entity.position.calcDistanceTo(player));
    const volume = Math.max(0, Math.pow(1 - (distance / quest.minDistance), 5));

    const [play, {stop, sound}] = useSound(quest.sound.url,
    {
        html5: false,
        loop: true,
        volume
    });

    const playing = sound?.playing();

    useEffect(() =>
    {
        play();

        return () =>
        {
            stop();
        }
    }, [sound]);

    function toggleSound()
    {
        if (playing)
            stop();
        else
            play();
    }


    let classes = classNames("sound",
    {
        "playing": playing
    });

    return <>
        <div className={classes} onClick={toggleSound}>
            <figure className="icon" />
        </div>
    </>
}

function FindEntityByCompassQuestPane({quest} : {quest:FindEntityByCompassQuest})
{
    const player = usePlayerPosition() ?? BORSIGPLATZ_POSITION

    const orientation = useContext(OrientationContext);

    let classes = classNames("compass",
    {
        "available": orientation !== undefined,
        "found": quest.entity.canVisitFrom(player)
    });

    const degreeNorth = -(orientation?.absolute ?? 0);
    const degreeEntity = player.calcDegreeTo(quest.entity.position);

    // console.log("degreeNorth", sensor.orientation, "degreeEntity", degreeEntity);

    const styles = {
        "--degree-north": `${degreeNorth}deg`,
        "--degree-entity": `${degreeEntity}deg`,
    } as CSSProperties

    return <>
        <div className={classes} style={styles} >
            <div className="arrow"></div>
        </div>
    </>
}

function FindEntityByPerimeterQuestPane({quest} : {quest:FindEntityByPerimeterQuest})
{
    if (quest.sound && quest.minDistance)
    {
        const player = usePlayerPosition() ?? BORSIGPLATZ_POSITION

        const distance = Math.floor(quest.entity.position.calcDistanceTo(player));
        const volume = Math.max(0, Math.pow(1 - (distance / quest.minDistance), 5));
    
        const [play, {stop, sound}] = useSound(quest.sound.url,
        {
            html5: false,
            loop: true,
            volume
        });
    
        const playing = sound?.playing();
    
        useEffect(() =>
        {
            play();
    
            return () =>
            {
                stop();
            }
        }, [sound]);
    }

    return <>
        <div className="hint">
            { quest.hintImageURL && <img src={quest.hintImageURL} />}
        </div>
    </>
}

function FindEntityByPhotoQuestPane({quest} : {quest:FindEntityByPhotoQuest})
{
    const {t:trans} = useTranslation();

    const [showMessage, setShowMessage] = useState<PolylingualString|undefined>(undefined);

    function match(match:boolean)
    {
        if (quest.match = match)
        {
            setShowMessage(quest.messageMatch);
        }
        else
        {
            setShowMessage(quest.messageMismatch);
        }
    }

    function reset()
    {
        setShowMessage(quest.match = undefined);
    }

    return <>
        <div className="photo">
            <PatternMatcher imageIds={[quest.imageId]} threshold={quest.threshold} 
                onImageMatch={match} onImageReset={reset} />

            { showMessage && <p className="message"> {trans(showMessage.i18nKey)} </p> }
        </div>
    </>
}

function SearchAreaQuestPane({quest} : {quest:SearchAreaQuest})
{
    let player = usePlayerPosition();

    if (player)
    {
        let classes = classNames("area",
        {
            "left": !quest.area.includes(player)
        });
    
        return <>
            <p className={classes}>Hier brauchst du nicht zu suchen!</p>       
        </>
    }
}
