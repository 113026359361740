import React, { useContext, useEffect, useRef, useState } from "react";

import SVG from 'react-inlinesvg';
import useMapEffects from "../hooks/useMapEffects";
import { MapEffect, MapEffectCleaner } from "../misc/MapEffect";

type StreetMapProps =
{

}

type ActiveMapEffect = [MapEffect, MapEffectCleaner]

export function StreetMap({} : StreetMapProps)
{
    const refSVG = useRef<SVGElement>(null);

    const effects = useMapEffects();
    const [activeEffects, setActiveEffects] = useState([] as ActiveMapEffect[]);

    useEffect(() =>
    {
        if (refSVG.current)
        // apply new effects
        for (let effect of effects)
        {
            let active = activeEffects.reduce((active, activeEffect) => active || activeEffect[0] == effect, false);

            if (!active)
            {
                let cleaner = effect.apply(refSVG.current);

                setActiveEffects(effects => [...effects, [effect, cleaner]]);
            }
        }

        // clean old effects
        for (let activeEffect of activeEffects)
        {
            let active = effects.reduce((active, effect) => active || activeEffect[0] == effect, false);

            if (!active)
            {
                activeEffect[1]();

                setActiveEffects(effects => effects.filter(effect => effect[0] != activeEffect[0]));
            }
        }

    }, [effects, refSVG.current]);

    return <>
        <div className="street-map">
            <SVG src="img/streetmap.svg" innerRef={refSVG} />
        </div>
    </>
}