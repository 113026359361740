import './i18n';

import { gsap } from "gsap";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";

import React, { memo } from "react";
import { createRoot } from 'react-dom/client';

import { GameContext } from "./context/GameContext";
import { GameInterface } from "./GameInterface";
import { Provider } from 'react-redux';
import { store } from './game';

// GSAP initialisieren
gsap.registerPlugin(MotionPathPlugin);

// React initialisieren
createRoot(document.getElementById('root')!).render
(
    <Provider store={store}>
        <GameContext>
            <GameInterface />
        </GameContext>
    </Provider>
);
